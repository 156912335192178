.card {
  --card-height: 46rem;
  --card-width: min(26rem, 100%);
  position: relative;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: clamp(0.85rem, 1.5vw, var(--font-size));
  justify-content: flex-start;
  max-width: var(--card-width);
  min-width: 20rem;
  /* width: 100%; */
  /* border: 0.2rem solid #fff; */
  /* border: 0.15rem solid var(--accent); */
  overflow: hidden;
  /* padding: 1em; */
  padding-bottom: 0px;

  border-radius: 0.5rem;
  /* margin: 2rem; */
  margin: 1.5rem 1rem;
  max-height: var(--card-height);
  height: var(--card-height);
  box-shadow: 0 0 0.25rem var(--accent), 0 0 0rem var(--accent), 0 0 1rem var(--primary), 0 0 1.5rem var(--primary), 0 0 0.1rem var(--primary), inset 0 0 0rem var(--primary);
  /* overflow: hidden; */

  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}

.card:hover {
  transform: scale(1.025);
  box-shadow: 0 0 0.2rem #fff, 0 0 0.2rem #fff, 0 0 2rem var(--primary), 0 0 1rem var(--primary), 0 0 2rem var(--primary), inset 0 0 1.3rem var(--primary);
  animation: neonGlow 1s ease-in-out infinite alternate;
  -webkit-animation: neonGlow 1s ease-in-out infinite alternate;
  -moz-animation: neonGlow 1s ease-in-out infinite alternate;
  transition: all 0.25s;
}

.card .mediaContainer {
  /* height: 30rem; */
  position: absolute;
  top: 0;
  inset-inline: 0;
  height: 300px;
  min-height: 300px;
  width: 100%;
  min-width: 100%;
  max-width: var(--card-width);
  object-fit: cover;
  object-position: top center;
  opacity: 1;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.75rem;
  transition: all 0.5s linear;
}

.mediaContainer.showExtendedDetails {
  /* height: 0px;
  min-height: 0px; */
  opacity: 0.125;
  transition: opacity 0.75s;
}

.card .detailContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  padding: 0 1rem;
  height: 100%;
  width: 100%;
  top: 300px;
  /* background-color: var(--background-color); */
  transition: all 0.5s linear;
}

.detailContainer.showExtendedDetails {
  top: 0;
  /* background-color: var(--background-color-transparent); */
}

.card .detailContainer > * {
  width: 100%;
}

.detailContainer .title {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 2em;
  height: 4em;
  text-transform: uppercase;
  letter-spacing: 0.125em;
  padding: 0.25em 0em 0.75em 0em;
  -webkit-text-stroke-width: 0.028em;
  -webkit-text-stroke-color: var(--text-glow);
  text-shadow: 0 0 2em var(--text-glow);
}

.detailContainer .tech {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-block: 1em; */
  font-size: 1.25em;
  font-weight: bolder;
  height: 3em;
  color: var(--text-glow);
}

.detailContainer .description {
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-grow: 1; */
  overflow: auto;
  text-align: left;
  font-family: 'Exo 2', sans-serif;
  font-size: 1.1em;
  font-weight: bold;
  line-height: 1.5rem;
  padding: 2em 1em 1em 1em;
  color: var(--box-glow);
}

.extendedDetails {
  /* position: absolute;
  top: calc(var(--card-height) + 200px);
  left: 1em; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 0px;
  opacity: 0;
  overflow-y: hidden;
  text-align: left;
  width: 100%;
  transition: all 0.5s linear;
  color: var(--accent);
  overflow: hidden;
  /* height: 100%;
  width: 100%; */
  /* height: var(--card-height);
  width: var(--card-width); */
}

.extendedDetails.showExtendedDetails {
  opacity: 1;
  height: 300px;
  /* /* height: 100%; */
  /* top: 0; */
  /* position: relative; */
  transition: all 0.5s linear;
}

.icons {
  /* background-color: var(--background-color); */
  display: flex;
  margin-top: auto;
  align-items: flex-end;
  justify-content: space-between;
  /* padding-block: 2em; */
  /* height: 3rem; */
  padding: 1rem 2rem;
  width: 100%;
  z-index: 1;
  /* background-color: yellow; */
}

.appear {
  animation: appear 2 linear forwards;
}

@keyframes appear {
  from {
    height: 0rem;
  }
  1% {
    height: var(--card-height);
  }

  to {
    height: var(--card-height);
  }
}
