@import 'Animations.css';
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--background-color);
  padding: 0.5em 1em;
  color: var(--primary);
  font-size: 2em;
  text-transform: uppercase;
  transition: 0.5s;
  letter-spacing: 0.25rem;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
  height: 1.75em;
  width: 100%;
  max-width: 20rem;
  border: 0.1rem solid #fff;
  border-radius: 0.5rem;
  /* background-color: var(--primary); */
  /* color: #050801; */
  opacity: 0;
  box-shadow: 0 0 5px var(--primary), 0 0 5px var(--primary),
    0 0 5px var(--primary), 0 0 200px var(--primary);
  transition: all 0.35s;
}

.btn:hover {
  transform: scale(1.25);
  background-color: var(--box-glow);
  color: #050801;
  box-shadow: 0 0 5px var(--primary), 0 0 25px var(--primary),
    0 0 50px var(--primary), 0 0 200px var(--primary);
}
.btn .active {
  animation: neonGlow 1s ease-in-out infinite alternate 3.5s;
}
.flip-in {
  animation: flip-in 2s linear forwards 3s;
}

@media screen and (min-width: 1000px) {
  .btn {
    max-width: 18rem;
  }
}
/*@media screen and (max-width: 799px) {
  .btn {
    height: 4rem;
    width: 20rem;
    margin: 1rem 0;
    font-size: 1.5rem;
  }
} */
