.gallery {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: 'column';
  align-items: center;
  justify-content: space-between;
}

.gallery .activeImage {
  /* max-width: 70vw;
  height: auto;
  max-height: 70vh; */
  width: 100%;
  height: auto;
  /* height: 100%;
  width: auto; */
  object-fit: contain;
  max-height: 70vh;
}

.gallery .control {
  /* min-width: 10vw; */
  display: flex;
}

.control.left {
  margin-left: -24px;
}
.control.right {
  margin-right: -24px;
}

@media (max-width: 800px) {
  .gallery {
    flex-wrap: wrap;
    align-items: flex-end;
  }
  .gallery .activeImage {
    max-width: 100%;
    order: 1;
  }

  .gallery .control {
    min-width: 10vw;
    order: 2;
  }
  /* .gallery .control::after {
    content: 'PREV';
  } */
}

.alignEnd {
  align-items: center;
  justify-content: end;
}
.alignStart {
  align-items: center;
  justify-content: start;
}
