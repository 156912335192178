.contact {
  /* background-color: yellow; */
  /* background-color: var(--background-color); */
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: var(--primary);
  margin-top: 10rem;
  left: 0;
  width: 100%;
  z-index: 2;
}

.contact .header {
  font-size: 1.5rem;
  margin-top: 5rem;
}

.contact .iconRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: center;
  margin: 0;
  padding: 2rem;
  width: 100%;
  max-width: 44rem;
}

.iconRow a {
  font-size: 20px;
}
