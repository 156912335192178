.iconBtn {
  cursor: pointer;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.7s;
  transform: scale(1);
}

.iconBtn:hover:not(.disabled) {
  transform: scale(1.2);
  -webkit-filter: drop-shadow(3px 3px 12px rgb(195, 7, 180));
  filter: drop-shadow(1px 2px 10px rgb(195, 7, 180));
}

/* ANIMATIONS */

/* Underline styles */
a {
  display: block;
  position: relative;
  padding: 0.2em 0;
  color: inherit;
  text-decoration: none;
}
a.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Fade in */
/* a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.1em;
  background-color: hotpink;
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
} */

a:hover::after,
a:focus::after {
  opacity: 1;
  transform: translate3d(0, 0.2em, 0);
}

/* Scale from center */
a::after {
  opacity: 1;
  transform: scale(0);
  transform-origin: center;
}

a:hover::after,
a:focus::after {
  transform: scale(1);
}
