* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
*:before,
*:after {
  box-sizing: inherit;
}

:root {
  --background-color: #000;
  --background-color-transparent: #000000d4;
  --font-size: 16px;
  --primary: #bc13fe;
  --secondary: #fff;
  --accent: rgb(4, 207, 247);
  --box-glow: #f82aff;
  --text-glow: #ff0fad;
  --text-shadow-base: 0 0 5px var(--secondary), 0 0 0.5px var(--secondary), 0 0 25px var(--text-glow), 0 0 15px var(--text-glow), 0 0 10px var(--text-glow),
    0 0 15px var(--text-glow), 0 0 20px var(--text-glow);
  --text-shadow-glow: 0 0 20px var(--secondary), 0 0 5px var(--secondary), 0 0 25px var(--text-glow), 0 0 25px var(--text-glow), 0 0 20px var(--text-glow),
    0 0 20px var(--text-glow), 0 0 20px var(--text-glow);
}

body {
  background-color: var(--background-color);
  height: 200vh;
  font-family: 'Righteous', 'Exo 2', sans-serif;
  overflow-y: scroll;
  scrollbar-color: var(--primary) var(--primary);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--primary);
}
