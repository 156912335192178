@import 'Animations.css';
.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  justify-content: space-between;
  position: fixed;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.97);
  border: 1px solid #ccc;
  z-index: 4;
  color: var(--primary);
  animation: fade-in 0.5s linear;
  transition: all 0.5s ease-out;
}
.container-content {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.modal.hide {
  opacity: 0;
  visibility: hidden;
  filter: blur(8px);
  transform: scale(0.33);
  box-shadow: 1rem 0 0 rgba(0, 0, 0, 0.2);
  animation: fade-in 1s linear reverse;

  @media (max-width: 600px) {
    .modal {
      /* padding: 2rem 0; */
    }
  }
}

.btn-area {
  padding: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-media {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--background-color);
  padding: 0.5rem 1rem;
  color: var(--primary);
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  transition: 0.5s;
  letter-spacing: 0.25rem;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
  height: 3rem;
  width: 15rem;
  border: 0.1rem solid #fff;
  /* border-radius: 0.5rem; */
  /* background-color: var(--primary); */
  /* color: #050801; */
  opacity: 1;
  /* box-shadow: 0 0 5px var(--primary), 0 0 5px var(--primary),
            0 0 5px var(--primary), 0 0 200px var(--primary);
          transition: all 0.25s; */

  -webkit-transition: all ease-in-out 0.8s;
  -moz-transition: all ease-in-out 0.8s;
  transition: all ease-in-out 0.8s;
}

.btn-media.left {
  border-radius: 0.5rem 0rem 0rem 0.5rem;
}
.btn-media.right {
  border-radius: 0rem 0.5rem 0.5rem 0rem ;
}

.btn-media.active {
  /* background-color: var(--box-glow);
          color: #050801; */
  color: var(--background-color);
  /* box-shadow: inset 15rem 0 0 0 var(--box-glow); */
  box-shadow: inset -15rem 0 0 0 var(--box-glow);
}

.btn-media.reverse {
  color: var(--box-glow);
  box-shadow: inset 0 0 0 var(--background-color);
}

.btn-media.reverse.active {
  color: var(--background-color);
  box-shadow: inset 15rem 0 0 var(--box-glow);
}

/* Underline styles */
button.close {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  font-weight: 700;
  border: 0;
  background-color: unset;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

@keyframes fade-in {
  from {
    opacity: 0;
    visibility: hidden;
    filter: blur(8px);
    transform: scale(0.33);
    box-shadow: 1rem 0 0 rgba(0, 0, 0, 0.2);
  }
  to {
    visibility: visible;
    box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);
    filter: blur(0);
    transform: scale(1);
    opacity: 1;
  }
}
