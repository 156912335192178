.container-video {
  /* width: auto;
  padding: 2rem;
  height: 100%;
  max-height: 70vh; */
  display: block;
  position: relative;
  height: auto;
  max-height: 70vh;
}

.container-video .controls {
  max-height: 70vh;
}
