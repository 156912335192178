.accelerated {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);

  -moz-transition: all 1s;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}
/*
  80s floor attribution
    https: //codepen.io/alvarotrigo/pen/oNeaYrw

*/
/* ............................................................... */

#retrobg-ground {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 50vh;
  bottom: 0;
  /* border-top: 2px solid #bf578c; */
  background-color: #000;
}

.retrobg-shutdown #retrobg-ground {
  border-color: #000;
}

#retrobg-groundShadow {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(#000 0%, transparent);
}

/* ............................................................... */
#retrobg-linesWrap {
  height: 100%;
  perspective: 1000px;
  perspective-origin: center top;
}

#retrobg-lines {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: top center;
  animation: 0.75s linear infinite retrobg-lines-anim;
}

.retrobg-shutdown #retrobg-lines {
  animation-duration: 5s;
}

@keyframes retrobg-lines-anim {
  from {
    transform: rotateX(84deg) translateY(0);
  }

  to {
    transform: rotateX(84deg) translateY(100px);
  }
}

#retrobg-hlines,
#retrobg-vlines {
  position: absolute;
  left: calc((900% - 100%) / -2);
  width: 900%;
  height: 500%;
}

#retrobg-vlines {
  display: flex;
  justify-content: center;
}

.retrobg-hline,
.retrobg-vline {
  width: 100%;
  height: 100%;
  background-color: #583c87;
  /* background-color: currentColor; */
}

.retrobg-hline {
  height: 3px;
}

.retrobg-vline {
  width: 3px;
}

.retrobg-hline + .retrobg-hline {
  margin-top: 98px;
}

.retrobg-vline + .retrobg-vline {
  margin-left: 48px;
}
