.content {
  background-color: var(--background-color);
  color: var(--primary);
  /* background: linear-gradient(to top, var(--background-color), 50%, rgba(0, 0, 0, 0.955), rgba(0, 0, 0, 0)); */
  min-height: 100vh;
  left: 0;
  /* padding: 10rem 1rem 0rem 1rem; */
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 108vh;
  width: 100%;
  z-index: 2;
  background: linear-gradient(to top, var(--background-color) 95%, rgba(0, 0, 0, 0.9));
}

@media (max-width: 600px) {
  .content {
    /* padding: 4rem 0; */
  }
}

.content .header {
  margin: 10rem 0 0 0;
  font-size: 10rem;
  position: sticky;
  z-index: -1;
  top: 0;
  left: 4rem;
  opacity: 0.7;
  -webkit-text-fill-color: var(--background-color); /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 0.25rem;
  -webkit-text-stroke-color: var(--primary-color);
}

.content .container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-evenly;
  margin-top: 6rem;
  padding: 2rem;
  height: 100%;
  width: 100%;
  max-width: 100rem;
  /* max-width: 100em; */
}

.content .container > * {
  flex: 1 1 100%;
}

.fade {
  height: 10rem;
  width: 100%;
  /* background-color: var(--background-color); */
  color: var(--primary);
  margin-top: -10rem;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0), rgb(0, 0, 0, 0.9));
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), rgb(0, 0, 0, 0.9));
  pointer-events: none;
}
