@import 'Animations.css';

/* TODO: FADE IN GLOW W.SUN */
.hero {
  --from: 0;
  --intro-copy-font-size: 1.125rem;
  --intro-copy-left: 50vw;
  --intro-copy-top: 4vh;
  --intro-copy-max-width: 40rem;
  --first_name-top: 41vh;
  --last_name-top: calc(var(--first_name-top) + 70px);
  --name-font-size: 4rem;
  --sun-max-width: 20rem;
  --sun-top: 30vh;
  --sun-left: 53vw;
  --sun-max-width: 18rem;
  --sun-top: 30vh;
  --sun-left: 52vw;
  position: absolute;
  left: var(--sun-left);
  width: var(--sun-max-width);
  top: 60vh;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  overflow: hidden;
  background-color: var(--background-color);
  background-image: linear-gradient(
    to bottom,
    black 0%,
    rgba(12, 4, 8, 0.869) 19%,
    rgba(26, 9, 16, 0.7705) 34%,
    rgba(41, 14, 26, 0.691) 47%,
    rgba(54, 18, 34, 0.639) 56.5%,
    rgba(67, 23, 42, 0.597) 65%,
    rgba(81, 27, 51, 0.563) 73%,
    rgba(93, 31, 59, 0.5375) 80.2%,
    rgba(103, 34, 65, 0.521) 86.1%,
    rgba(109, 37, 69, 0.5105) 91%,
    rgba(113, 38, 71, 0.504) 95.2%,
    rgba(115, 39, 73, 0.501) 98.2%,
    rgba(116, 39, 73, 0.5) 100%
  );
  background-repeat: no-repeat;
  background-position: 0 0;
  color: var(--primary);
  perspective: 800px;
  will-change: auto;
  padding: 2rem;
}

.spaceship {
  position: absolute;
  left: -10vw;
  width: 1.2rem;
  animation: flight 30s forwards infinite ease-in 3s;
}

.ufo {
  width: 1.2rem;
  position: absolute;
  left: 110vw;
  top: 43vh;
  animation: flight 20s reverse infinite ease-in 10s;
}

.name {
  position: absolute;
  letter-spacing: 4px;
  left: 50vw;
  z-index: 19;
  transform: translate(-50%);
}

.first_name {
  top: var(--first_name-top);
  font-size: var(--name-font-size);
  will-change: auto;
  animation: first_name_intro forwards 2s;
}

.last_name {
  top: calc(var(--first_name-top) + 40px);

  font-size: 0px;
  will-change: transform;
  z-index: 18;
  animation: last_name_intro forwards ease-out 3.25s 1.5s;
}

.section-intro-copy {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  text-align: center;
  font-size: var(--intro-copy-font-size);
  left: var(--intro-copy-left);
  top: var(--intro-copy-top);
  height: 15rem;
  width: 100%;
  max-width: var(--intro-copy-max-width);
  font-family: 'Cairo', sans-serif;
  font-weight: 500;
  opacity: 0;
  color: #fff;
  padding: 2rem;
  /* word-wrap: none;
  white-space: nowrap; */
  /* text-shadow: 0 0 20px #fff, 0 0 10px #fff, 0 0 50px #ff0fad, 0 0 50px #ff0fad,
    0 0 40px #ff0fad, 0 0 100px #ff0fad, 0 0 75px #ff0fad; */
  /* color: var(--primary); */
  z-index: 1;
  transform: translate(-50%);
  animation: copy-text 0.75s linear forwards 4.5s, textGlow 2.5s ease-in-out infinite alternate;
}

.sun {
  position: absolute;
  left: var(--sun-left);
  width: var(--sun-max-width);
  top: 60vh;
  z-index: 0;
  transform: translate(-50%);
  animation: sunrise forwards linear 2.5s 2s;
}

.section-cta {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  bottom: 2vh;
  left: 0;
  width: 100%;
  height: 35vh;
  padding: 1rem 3rem;
  /* background-color: red; */
}

@media screen and (min-width: 699px) {
  .hero {
    --from: 0;
    --intro-copy-font-size: 1.5rem;
    --intro-copy-left: 50vw;
    --intro-copy-top: 4vh;
    --intro-copy-max-width: 45rem;
    --last_name-top: calc(var(--first_name-top) + 90px);
    --first_name-top: 38vh;
    --name-font-size: 5.5rem;
    --sun-max-width: 25rem;
    --sun-top: 26vh;
    --sun-left: 52vw;
  }
}

@media screen and (min-width: 999px) {
  .hero {
    --from: 0;
    --first_name-top: 35vh;
    --intro-copy-font-size: 1.5rem;
    --intro-copy-left: 50vw;
    --intro-copy-max-width: 50rem;
    --intro-copy-top: 2vh;
    --last_name-top: calc(var(--first_name-top) + 120px);
    --name-font-size: 8rem;
    --sun-max-width: 35rem;
    --sun-top: 15vh;
    --sun-left: 51vw;
  }

  .section-cta {
    flex-direction: row;
  }
}
/* @media screen and (min-width: 300px) {
  .first_name,
  .last_name {
    --name-font-size: 4rem;
    --first_name-top: 31vh;
    --last_name-font-top: 41vh;
  }

  .sun {
    --sun-max-width: 18rem;
    --sun-top: 23vh;
    --sun-left: 52vw;
  }

  .section-cta {
    flex-direction: column;
    align-items: center;
    height: 40vh;
    bottom: 2rem;
  }
  .section-cta > * {
    width: 20rem;
    margin: 1.5rem 0;
  }

  .section-intro-copy {
    top: 1.5rem;
    word-wrap: none;
    white-space: unset;
    width: 100vw;
  }
}

@media screen and (min-width: 600px) {
  .first_name,
  .last_name {
    --name-font-size: 6rem;
    --first_name-top: 31vh;
    --last_name-font-top: 41vh;
  }

  .sun {
    --sun-max-width: 18rem;
    --sun-top: 23vh;
    --sun-left: 52vw;
  }

  .section-cta {
    flex-direction: column;
    align-items: center;
    height: 40vh;
    bottom: 2rem;
  }

  .section-cta > * {
    width: 20rem;
    margin: 1.5rem 0;
  }

  .section-intro-copy {
    top: 1.5rem;
    word-wrap: none;
    white-space: unset;
    width: 100vw;
  }
}

@media screen and (min-width: 800px) {
  .first_name,
  .last_name {
    --name-font-size: 10em;
    --first_name-top: 17vh;
    --last_name-font-top: 32vh;
    --sun-left: 52vw;
  }

  .sun {
    --sun-max-width: 32rem;
    --sun-top: 16vh;
    --sun-left: 52vw;
  }
} */

/* EFFECTS, ANIMATIONS */

.metal {
  background: linear-gradient(
    hsl(239, 50%, 30%) 15%,
    hsl(239, 50%, 40%) 25%,
    hsl(200, 60%, 50%) 35%,
    hsl(100, 70%, 80%) 45%,
    hsl(60, 100%, 98%) 50%,
    hsl(240, 0%, 0%) 52%,
    hsl(240, 10%, 10%) 60%,
    hsl(240, 50%, 30%) 70%,
    hsl(220, 70%, 60%) 80%,
    hsl(212, 92%, 76%) 85%
  );
  -webkit-text-stroke: 2px hsl(200, 65%, 80%);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.raise {
  filter: drop-shadow(1px 3px 0px hsl(242, 54%, 10%)) drop-shadow(1px 3px 0px hsl(242, 54%, 15%)) drop-shadow(1px 3px 0px hsl(242, 54%, 20%))
    drop-shadow(1px 3px 0px hsl(242, 54%, 25%)) drop-shadow(1px 3px 0px hsl(242, 54%, 30%)) drop-shadow(0px 0px 10px rgba(16, 16, 16, 0.4));
}

.shine:after {
  content: attr(data-text);
  background-image: linear-gradient(225deg, transparent 53%, white 55%, transparent 58%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-clip: text;
  -webkit-background-clip: text;
  z-index: 999;
  background-size: 400% 400%;
  animation: shine 10s ease infinite;
  animation-delay: var(--shine-delay, 0s);
  pointer-events: none;
}

/*stars*/
.stars:after {
  transform: translateY(-40%);
  content: ' ';
  border-radius: 100%;
  width: 3px;
  height: 4px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  box-shadow: 5vw 15vh 2px white, 1vw 33vh 0px white, 2vw 25vh 2px white, 10vw 10vh 2px white, 12vw 20vh 0px white, 30vw 15vh 2px white, 16vw 5vh 2px white, 24vw 10vh 0px white,
    32vw 40vh 0px white, 33vw 35vh 2px white, 12vw 38vh 2px white, 24vw 10vh 0px white, 33vw 5vh 2px white, 20vw 10vh 0px white, 80vw 10vh 2px white, 62vw 20vh 0px white,
    60vw 15vh 2px white, 70vw 7vh 0px white, 62vw 50vh 0px white, 65vw 35vh 2px white, 64vw 10vh 0px white, 85vw 2vh 0px white, 92vw 40vh 0px white, 75vw 35vh 2px white,
    90vw 10vh 0px white;
  opacity: 0.3;
  animation: glitter 2s infinite;
}

@keyframes flight {
  from {
    left: -10vw;
  }
  to {
    left: 150vw;
  }
}

@keyframes textGlow {
  from {
    text-shadow: var(--text-shadow-base);
  }
  to {
    text-shadow: var(--text-shadow-glow);
  }
}
@keyframes copy-text {
  from {
    opacity: 0;
    transform: translate(-50%) scaleX(0);
  }
  to {
    opacity: 1;
    /* width: 80vw; */
    transform: translate(-50%) scaleX(1);
    /* max-width: 50rem; */
  }
}

@keyframes shine {
  0% {
    background-position: 0% 51%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 51%;
  }
}

@keyframes sunrise {
  from {
    top: 60vh;
  }

  to {
    top: var(--sun-top);
  }
}
@keyframes first_name_intro {
  from {
    top: 1vh;
    font-size: 0rem;
  }

  to {
    display: block;
    top: var(--first_name-top);
    font-size: var(--name-font-size);
    transform: translate(-50%);
  }
}
@keyframes last_name_intro {
  from {
  }

  to {
    display: block;
    top: var(--last_name-top);
    font-size: var(--name-font-size);
  }
}
