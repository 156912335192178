.hover-elevate-glow:hover {
  transform: scale(2.025);
  box-shadow: 0 0 0.2rem #fff, 0 0 0.2rem #fff, 0 0 2rem var(--primary),
    0 0 1rem var(--primary), 0 0 2rem var(--primary),
    inset 0 0 1.3rem var(--primary);

  /* animation: neonGlow 1s ease-in-out infinite alternate;
  -webkit-animation: neonGlow 1s ease-in-out infinite alternate;
  -moz-animation: neonGlow 1s ease-in-out infinite alternate; */
}
.neon-glow {
  animation: neonGlow 1s ease-in-out infinite alternate 3.5s;
  -webkit-animation: neonGlow 1s ease-in-out infinite alternate 3.5s;
  -moz-animation: neonGlow 1s ease-in-out infinite alternate 3.5s;
}

.light {
  /* box-shadow: 0 0 5px var(--primary), 0 0 25px var(--primary),
  0 0 50px var(--primary), 0 0 200px var(--primary); */
  /* TODO: MOVE THIS TO OWN CLASS */
  transform: scale(1.025);
  transition: all 1s;
}
.light div {
  position: absolute;
}
.light:hover {
  transform: scale(1.25);
  background-color: var(--box-glow);
  color: #050801;
  box-shadow: 0 0 5px var(--primary), 0 0 25px var(--primary),
    0 0 50px var(--primary), 0 0 200px var(--primary);
}
.light div:nth-child(1) {
  width: 100%;
  height: 4.5px;
  top: 0;
  left: -100%;
  background: linear-gradient(to right, transparent, var(--box-glow));
  animation: animate1 2.5s linear infinite 2s;
}
.light div:nth-child(2) {
  width: 4.5px;
  height: 100%;
  top: -100%;
  right: 0;
  background: linear-gradient(to bottom, transparent, var(--box-glow));
  animation: animate2 2.5s linear infinite 2s;
  animation-delay: 0.25s;
}
.light div:nth-child(3) {
  width: 100%;
  height: 4.5px;
  bottom: 0;
  right: -100%;
  background: linear-gradient(to left, transparent, var(--box-glow));
  animation: animate3 2.5s linear infinite 2s;
  animation-delay: 0.5s;
}
.light div:nth-child(4) {
  width: 4.5px;
  height: 100%;
  bottom: -100%;
  left: 0;
  background: linear-gradient(to top, transparent, var(--box-glow));
  animation: animate4 2.5s linear infinite 2s;
  animation-delay: 0.75s;
}

@keyframes fade-in {
  from {
    opacity: 0.5;
  }
  top {
    opacity: 1;
  }
}

@keyframes flip-in {
  from {
    transform: rotateX(-90deg);
    opacity: 0;
  }

  50% {
    transform: rotateX(-45deg);
    transform-origin: 100% 2.5rem; /* half of height */
    opacity: 0.5;
  }

  to {
    transform: rotateX(0deg);
    opacity: 1;
  }
}

@keyframes animate1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}
@keyframes animate2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}
@keyframes animate3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}
@keyframes animate4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}

/*glow*/

@keyframes neonGlow {
  from {
    box-shadow: 0 0 0.2rem #fff, 0 0 0.2rem #fff, 0 0 2rem #f82aff,
      0 0 1.1rem #f82aff, 0 0 1.2rem #f82aff, inset 0 0 1.3rem #f82aff;
  }
  to {
    box-shadow: 0 0 0.2rem #fff, 0 0 0.2rem #fff, 0 0 2rem #f82aff,
      0 0 1rem #f82aff, 0 0 2rem #f82aff, inset 0 0 1.3rem #f82aff;
  }
}

/* mozilla*/

@-moz-keyframes neonGlow {
  from {
    box-shadow: 0 0 0.2rem #fff, 0 0 0.2rem #fff, 0 0 2rem #f82aff,
      0 0 0.8rem #f82aff, 0 0 1.2rem #f82aff, inset 0 0 1.3rem #f82aff;
  }
  to {
    box-shadow: 0 0 0.2rem #fff, 0 0 0.2rem #fff, 0 0 2rem #f82aff,
      0 0 1rem #f82aff, 0 0 2rem #f82aff, inset 0 0 1.3rem #f82aff;
  }
}

/*webkit*/

@-webkit-keyframes neonGlow {
  from {
    box-shadow: 0 0 0.2rem #fff, 0 0 0.2rem #fff, 0 0 2rem #f82aff,
      0 0 0.8rem #f82aff, 0 0 1.2rem #f82aff, inset 0 0 1.3rem #f82aff;
  }
  to {
    box-shadow: 0 0 0.2rem #fff, 0 0 0.2rem #fff, 0 0 2rem #f82aff,
      0 0 1rem #f82aff, 0 0 2rem #f82aff, inset 0 0 1.3rem #f82aff;
  }
}

/*glow*/

@keyframes neonGlow {
  from {
    box-shadow: 0 0 0.2rem #fff, 0 0 0.2rem #fff, 0 0 2rem var(--primary),
      0 0 1.1rem var(--primary), 0 0 1.2rem var(--primary),
      inset 0 0 1.3rem var(--primary);
  }
  to {
    box-shadow: 0 0 0.2rem #fff, 0 0 0.2rem #fff, 0 0 2rem var(--primary),
      0 0 1rem var(--primary), 0 0 2rem var(--primary),
      inset 0 0 1.3rem var(--primary);
  }
}

/* mozilla*/

@-moz-keyframes neonGlow {
  from {
    box-shadow: 0 0 0.2rem #ffffff00, 0 0 0.2rem #ffffff00,
      0 0 2rem var(--primary), 0 0 0.8rem var(--primary),
      0 0 1.2rem var(--primary), inset 0 0 1.3rem var(--primary);
  }
  to {
    box-shadow: 0 0 0.2rem #fff, 0 0 0.2rem #fff, 0 0 2rem var(--primary),
      0 0 3rem var(--primary), 0 0 4rem var(--primary),
      inset 0 0 1.3rem var(--primary);
  }
}

/*webkit*/

@-webkit-keyframes neonGlow {
  from {
    box-shadow: 0 0 0.2rem #ffffff00, 0 0 0.2rem #ffffff00,
      0 0 2rem var(--primary), 0 0 0.8rem var(--primary),
      0 0 1.2rem var(--primary), inset 0 0 1.3rem var(--primary);
  }
  to {
    box-shadow: 0 0 0.2rem #fff, 0 0 3rem #fff, 0 0 12rem var(--primary),
      0 0 4rem var(--primary), 0 0 2rem var(--primary),
      inset 0 0 4rem var(--primary);
  }
}
